export const langEn = {
	'SNU Linguistics 10-10 project': 'SNU LINGUISTICS\n10-10 PROJECT',

	login: 'login',
	'Sign Up': 'Sign Up',
	'Email Address': 'Email Address',
	password: 'Password',
	'confirm password': 'Confirm Password',
	logout: 'logout',
	quit: 'withdrawal',
	'no account': 'No Account?',
	'send email': 'Send e-mail',
	'email sent': 'e-mail Sent',
	'check email': 'Check your e-mail and input the code',
	'certificaton success': 'Certified',

	Experiments: 'Experiments',
	experiments: 'experiments',
	'show filter': 'Show Filter',
	'hide filter': 'Hide Filter',
	seeOnlyAvailable: 'Show available experiments only',
	status: 'Status',
	language: 'Language',
	lists: 'Lists',
	'my experiments': 'My Experiments',
	'experiment type': 'Experiment Type',
	'In Progress': 'In Progress',
	Unpublished: 'Unpublished',
	Closed: 'Closed',
	joined: 'Joined',
	available: 'Available',
	unavailable: 'Unavailable',

	'target language': 'Target Language',
	reward: 'Reward',
	participants: 'Participants',
	link: 'Website Link',
	code: 'Confirmation Code',
	location: 'Location',
	duration: 'Duration',
	min: 'min',
	none: 'none',

	'go back': 'Go Back',
	korean: 'Korean',
	english: 'English',
	japanese: 'Japanese',
	chinese: 'Chinese',
	french: 'French',
	spanish: 'Spanish',

	Online: 'Web',
	Offline: 'Lab',

	schedule: 'Schedule',
	colloquium: 'Colloquium',
	seminar: 'Seminar',
	news: 'News',

	'My Page': 'My Page',

	Confirm: 'Confirm',
	address:
		'Department of Linguistics, Seoul National University, Gwanak_1 Gwanak-ro, Seoul 151-745, Korea',
	contacts: 'Email: linguist@snu.ac.kr Tel:+82-2-880-6164, Fax:+82-2-882-2451.',
};
